<template>
  <div class="main-box">
    <div class="headr-box headr-box-pc">
      <div class="headr-go" @click="goNav">
        <i class="el-icon-arrow-left"></i>
        <span class="pc-show">返回提交页</span>
        <span class="h5-show">返回</span>
      </div>
      <UserLogin class="user-login" @userInfoSelf="acceptUserLogin"></UserLogin>
    </div>
    <div class="title-box">
      <h1>{{ title }}</h1>
    </div>
    <div class="cont-box">
      <LeftMenu class="h5-show" @outUserLogin="outUserLogin"></LeftMenu>
      <!-- 插槽 -->
      <slot></slot>
    </div>
  </div>
</template>
<script>
import UserLogin from "@/components/userLogin/index.vue"
import LeftMenu from "@/views/user_promot/components/LeftMenu/index.vue"
import {EventBus} from "@/utils/eventBus"
export default {
  props: ['title'],
  components: {
    LeftMenu,
    UserLogin
  },
  data() {
    return {
      userInfo: {
        isLogin: false, // 用户登录状态
        username: '',
        nickname: '',
        token: ''
      }
      // showLeftMenu: true
    }
  },
  created() {
    // let toPath = this.$route.path
    // this.showLeftMenu = toPath !== '/index/promot'
  },
  methods: {
    goNav(){
      localStorage.setItem('currentIndex',1)
      this.$router.push('/index')
    },
    acceptUserLogin(msg){
      if (msg && msg.user === false){
        this.userInfo = this.$options.data().userInfo
        this.$emit('userInfo',msg)
      }else {
        this.userInfo = msg.info
        this.$emit('userInfo',msg)
      }
    },
    outUserLogin(msg){
      if (msg && msg.user === false){
        // this.userInfo = this.$options.data().userInfo
        // localStorage.removeItem('userInfo')
        this.userInfo = this.$options.data().userInfo
        localStorage.removeItem('userInfo')
        localStorage.removeItem('userSetInfo')
        // 传递 退出登录 的消息
        EventBus.$emit("userOutLogin", 'userOutLoginTrue')
        this.$router.push('/index/promot')
      }
    }
  }
}
</script>
<style scoped lang="less">
@import "@/views/user_promot/user-main";
</style>
